body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.hero__three-container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.hero__content {
  width: 100%;
  padding: 0 5%;
  text-align: center;
}

@media (min-width: 768px) {
  .hero__content {
    padding: 0 10%;
  }
}

.hero__content h1,
.hero__content p {
  margin: 0;
  font-family: 'Orbitron', 'Exo 2', sans-serif;
  font-size: 1.5rem; /* Adjust size as needed */
  font-weight: 400; /* Use 700 for bold */
  text-transform: uppercase; /* For a strong futuristic look */
  letter-spacing: 0.05em; /* Slight letter spacing for a spaced-out feel */
  color: #D9E1E9; /* Light futuristic color */
}


.hero__trigger-button {
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 0.875rem;
  position: absolute;
  z-index: 2;
  left: 50%;
  margin-left: -30px;
  background: none;
  border: none;
  color: inherit;
  text-align: center;
  cursor: pointer;
  transform: rotate(270deg);
}

.hero__trigger-button.top {
  top: 40px;
}

.hero__trigger-button.top:hover {
  opacity: 1;
  top: 35px;
}

.hero__trigger-button.bottom {
  bottom: 40px;
}

.hero__trigger-button.bottom:hover {
  opacity: 1;
  bottom: 35px;
}
/* Brand new class for the right button */
.hero__right-button {
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 0.875rem;
  position: absolute;
  z-index: 2;
  top: 50%; /* Center vertically */
  right: 20px; /* Align to the right */
  transform: translateY(-50%) rotate(0deg); /* Remove rotation for horizontal text */
  background: none; /* No background */
  border: none; /* No border */
  color: inherit; /* Inherit color */
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.hero__right-button:hover {
  opacity: 1;
  right: 5px; /* Slight inward movement on hover */
}

.logo {
  position: absolute;
  top: 2.5rem;
  left: 1.25rem;
  z-index: 10;
}

@media (min-width: 768px) {
  .logo {
    left: 1.875rem;
  }
}

@media (min-width: 992px) {
  .logo {
    left: 2.5rem;
  }
}

.meta {
  position: absolute;
  z-index: 10;
}

.meta.sound {
  bottom: 2.5rem;
  right: 1.25rem;
}

.mute-action.muted {
  background-position: top right;
}

.hero.sphere,
.hero.field {
  background: #FBFFFF;
}

.hero.dark {
  background-image: radial-gradient(50% 100%, #080808 50%, #14161B 100%);
}

.hero.dark .hero__content p,
.hero.dark .hero__content h1 {
  color: #D9E1E9;
}

.hero.dark .hero__trigger-button {
  color: #D9E1E9;
}

.hero.dark .meta.name p {
  color: #7B8085;
}

.hero.dark .meta.sound a {
  background-image: url("./images/icon-sound-light.png");
}
.grain {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 1; /* Behind ParticleBackground but above section background */
  opacity: 0.1; /* Make the grain effect barely visible */
}

.grain:before {
  content: "";
  background: url("./images/glitch.jpg") no-repeat 50% 50% fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 98;
  pointer-events: none;
  animation: shift 0.5s steps(5, end) infinite, flicker 0.2s steps(2, start) infinite;
  opacity: 0.2; /* Further reduce visibility of the glitch effect */
}

.no-csspointerevents .grain {
  display: none;
}

/* Shifting effect for glitching */
/* @keyframes shift {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-20px, -20px); } 
} */

/* Flickering effect for fast glitching visibility */
@keyframes flicker {
  0%, 100% { opacity: 0.4; } /* Visible */
  50% { opacity: 0.05; } /* Almost invisible for a fast flicker */
}
